(function (ghd) {

    ghd.plugins.register('slick',
        function () {

            return {
                init: function () {
                    var $ = window.$;
                    var homeSliderElements = $(".home-slider");
                    homeSliderElements.find('img').css('display', '');
                    if (!!homeSliderElements.length) {
                        homeSliderElements.slick({
                            arrows: false,
                            infinite: true,
                            dots: false,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            draggable: false,
                            swipe: true
                        });
                    }


                },
            }
        });

})(window.ghd);
