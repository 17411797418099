(function (ghd) {

    ghd.plugins.register('productListing',
        function () {

            return {
                init: function () {
                    var $ = window.$;
                    function productListingFunc() {
                        // phone
                        // if (Modernizr.mq('(max-width: 767px)')) {
                        //     $('.product-title-wrapper').css('height', 'initial');
                        //     $('.product-small').each(function (idx, el) {
                        //         $(el).css('height', '');
                        //         var img = $(el).find('img');
                        //         img.css('width', '100%');
                        //         img.css('height', '');
                        //         // var packageDescriptionBtn = $(el).find('.product-description-btn');
                        //         // packageDescriptionBtn.css('width', '100%');
                        //         // packageDescriptionBtn.css('padding', '0 5%');
                        //         var packageBtn = packageDescriptionBtn.find('.product-btn');
                        //         packageBtn.css('margin', '10px 0');
                        //         packageBtn.removeClass('col-sm-6');
                        //     });
                        // } else { // tablet or desktop
                        //     $('.product-small').each(function (idx, el) {
                        //         var packageBtn = $(el).find('.product-btn');
                        //         packageBtn.css('margin', '0');
                        //         var img = $(el).find('img');
                        //         var packageTitleWrapper = $(el).find('.product-title-wrapper');
                        //         var imgH = img.outerHeight(true);
                        //         var h = packageTitleWrapper.outerHeight(true);
                        //         var newImgHeight = img.outerWidth();
                        //         img.css('height', newImgHeight);
                        //         // var packageDescription = $(el).find('.product-description')
                        //         // packageDescription.css('padding-top', '10px');
                        //         // $clamp(packageDescription[0], { clamp: (newImgHeight * 0.65) + 'px' }); // ellipsis

                        //         // packageDescription.find('p').css('overflow', 'hidden');

                        //         var packageBtn = $(el).find('.product-btn');
                        //         packageBtn.addClass('col-sm-6');
                        //     });
                        //     var topOffsetToMaxHeightMap = new Map();
                        //     $('.product-title-wrapper').css('height', 'initial');
                        //     $('.product-title-wrapper').each(function (idx, el) {
                        //         var topOffset = $(el).offset().top;
                        //         var height = $(el).outerHeight();
                        //         if (topOffsetToMaxHeightMap.has(topOffset)) {
                        //             var h = topOffsetToMaxHeightMap.get(topOffset);
                        //             if (height > h) {
                        //                 topOffsetToMaxHeightMap.set(topOffset, h);
                        //             }
                        //         } else {
                        //             topOffsetToMaxHeightMap.set(topOffset, height);
                        //         }
                        //     });
                        //     $($('.product-title-wrapper').get().reverse()).each(function (idx, el) {
                        //         var topOffset = $(el).offset().top;
                        //         var h = topOffsetToMaxHeightMap.get(topOffset);
                        //         $(el).height(h);
                        //     });
                        // }
                        // if (!Modernizr.objectfit) {
                        //     $('.product').addClass('object-fit');
                        //     $('.product-image-container').each(function () {
                        //         var $container = $(this),
                        //             $img = $container.find('img'),
                        //             imgUrl = $img.prop('src');
                        //         if (imgUrl) {
                        //             $container
                        //                 .css('backgroundImage', 'url(' + imgUrl + ')')
                        //                 .addClass('compat-object-fit');
                        //         }
                        //     });
                        // }

                    }

                    productListingFunc();
                    window.addEventListener("resize", _.debounce(function () {
                        productListingFunc();
                    }, 300));
                }
            }
        });

})(window.ghd);
