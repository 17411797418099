(function(ghd) {
    var $ = window.$;

    ghd.plugins.register('pickadate_themeSwitcher',
        function () {

            return {
                init: function() {

                    $("body").addClass("pickadate--default");

                },
                onWindowResize: function (isDesktop) {

                    if (isDesktop) {
                        $("body").addClass("pickadate--classic");
                        $("body").removeClass("pickadate--default");
                    } else {
                        $("body").addClass("pickadate--default");
                        $("body").removeClass("pickadate--classic");
                    }

                }
            }
        });

})(window.ghd);