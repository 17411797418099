(function (ghd) {

    ghd.plugins.register('modalImage',
        function () {

            return {
                init: function () {

                    var $ = window.$;
                    // Get the modal
                    var modal = $('.modal-image');

                    // Get the image and insert it inside the modal - use its "alt" text as a caption
                    var img = $('.modal-image-to-show');
                    var modalImg = $('.modal-image .modal-content');
                    var captionText = $('.modal-image .modal-caption');
                    img.on('click', function () {
                        modal.css('display', 'block');
                        var srcLarge = $(this).attr('srcLarge');
                        if (!!srcLarge) {
                            modalImg.attr('src', srcLarge);
                        } else {
                            modalImg.attr('src', this.src);
                        }

                        captionText.html(this.alt);
                    });
                    modal.on('click', function () {
                        modal.css('display', 'none');
                    });
                    $('.expandable').on('click', function () {
                        $(this).siblings('img.modal-image-to-show').click();
                    });
                }
            }
        });

})(window.ghd);
