/*
PLUGINS MANAGER
In order to avoid plugins (Jquery plugins, custom Jquery functions, events subscriptions)
being initalized or referred all over the place, all functions MUST be `registered` with this manager class.
This approach makes easier to debug code as all running plugins can be found at `window.ghd.plugins._components`.
Also, events subscription are easily managed as plugins should not regfister to window events on its own.
They all should register themselves with the plugin manager which then will subscribe to window events only once.
*/
 

(function (window) {

    var _ = window._;
    var $ = window.$;

    if (!window.ghd) {
        window.ghd = {};
    }

    function debounceResize(c, t) { window.onresize = function () { clearTimeout(t); t = setTimeout(c, 100) }; return c };

    window.ghd.plugins = {
        //mother object for all plugins
        _components: {},

        //list of plugin names that should be notified on window Resize
        _resizeAwarePlugins: [],

        //list of plugin names that should be notified on window Resize
        _scrollAwarePlugins: [],

        //plugin registration method. All plugins must be added using this method in order to be initialized
        register: function (pluginName, pluginFunction) {

            var that = this;

            if (!!that._components[pluginName]) {
                throw new Error("plugin '" + pluginName + "' cannot be initialized more than once.");
            }

            var pluginObject = pluginFunction();

            //check for required init() method
            if (!_.isFunction(pluginObject.init)) {
                throw new Error("plugin '" + pluginName + "' must have an init() function.");
            }

            //has it got a method signature for onWindowResize?
            if (_.isFunction(pluginObject.onWindowResize)) {
                that._resizeAwarePlugins.push(pluginName);
            }

            //has it got a method signature for onWindowScroll?
            if (_.isFunction(pluginObject.onWindowScroll)) {
                that._scrollAwarePlugins.push(pluginName);
            }

            that._components[pluginName] = pluginObject;

            //            console.log("plugin '" + pluginName + "' registered succesfully.");

        },

        //Mother init() method.
        //It will iterate over all plugins calling each init() method
        //It will also subscribe to onWindowResize and onWindowScroll events ONCE for all plugins.
        init: function () {
            var that = this,
                onWindowResize = _.debounce(function () { that._onWindowResize(); }, 100),
                onWindowScroll = _.debounce(function () { that._onWindowScroll(); }, 100);

            $(document)
                .ready(function () {

                    //INIT
                    //call all plugin initialize functions
                    that._onInit();

                    //WINDOW RESIZE
                    //call all plugins that subscribed for this event.
                    debounceResize(function () { onWindowResize(); })();

                    //WINDOW SCROLL
                    //call all plugins that subscribed for this event.
                    $(window).scroll(function () { onWindowScroll(); });

                });
        },

        _onInit: function () {
            var that = this;

            for (var key in that._components) {
                that._components[key].init.call(that._components[key]);
            }
        },

        _onWindowResize: function () {
            var that = this,
                isDesktop = Modernizr.mq('(min-width: 768px)'); //should match: .less @screen-sm-min

            for (var i = 0; i < that._resizeAwarePlugins.length; i++) {
                var key = that._resizeAwarePlugins[i];
                that._components[key].onWindowResize.call(that._components[key], isDesktop);
            }
        },
        _onWindowScroll: function () {
            var that = this;

            for (var i = 0; i < that._scrollAwarePlugins.length; i++) {
                var key = that._scrollAwarePlugins[i];

                that._components[key].onWindowScroll.call(that._components[key]);
            }
        }

    };

})(window);