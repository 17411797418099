﻿(function (ghd) {

    ghd.plugins.register('tabPanels',
        function () {

            var $ = window.$;
            return {
                init: function ($parent) {

                    var $tabComponents = $(".gha-tab", $parent),
                        linksSelector = '[role=tab]',
                        panelsSelector = '[role=tabpanel]';

                    $.each($tabComponents,
                        function () {

                            var $widget = $(this),
                                $links = $(linksSelector, $widget),
                                $panels = $(panelsSelector, $widget);

                            if (!$links.length) {
                                throw new Error('Tab component: No tab navigation item matched jQuery selector "' +
                                    linksSelector +
                                    '".');
                            }
                            if (!$panels.length) {
                                throw new Error('Tab component: No tab panel matched jQuery selector "' +
                                    panelsSelector +
                                    '".');
                            }


                            $widget.off('.gha-tab');
                            $widget.on('click.gha-tab',
                                linksSelector,
                                function (e) {
                                    var $link = $(this),
                                        targetId = "#" + $(this).data("target"),
                                        $links = $(linksSelector, $widget),
                                        $panels = $(panelsSelector, $widget);

                                    $links.removeClass("active");
                                    $panels.removeClass("active");
                                    $link.addClass("active");
                                    $(targetId).addClass("active");
                                });
                        });

                }
            }
        });
})(window.ghd);