(function (ghd) {
    ghd.plugins.register('expand-element-on-click',
        function () {

            return {
                init: function () {
                    function toggleVisibilityPasses($headers) {
                        var $detail = $('.c-header__body', $headers.parents(".parent-to-hide"));
                        var $chevron = $('.c-header__chevron', $headers);

                        $detail.toggleClass('hide');

                        var shouldShow = $detail.hasClass('hide')

                        $chevron.toggleClass('fa-chevron-down', !shouldShow);
                        $chevron.toggleClass('fa-chevron-right', shouldShow);

                        $headers.toggleClass('c-header--expanded');
                    }

                    var $ = window.$;

                    var elementsToExpand = $(".expand-on-click");

                    elementsToExpand.on("click", ".c-header", function () {
                        var $header = $(this);


                        toggleVisibilityPasses($header);
                    });


                }
            }
        });


})(window.ghd);
