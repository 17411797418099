(function (ghd) {

    ghd.plugins.register('busyMessagesForMVC',
        function () {

            return {
                init: function () {

                    var $ = window.$;
                    // CONTACT US - SEND ENQUIRY
                    $("#btnSend").on('click', function (token) {
                        $(this).html("<div class='btn-content-centered'><i class='fa fa-refresh fa-spin'></i><span class='span-margin'>Sending...</span></div>");
                        $(this).attr('disabled', true);
                        $(this).closest('form').trigger('submit')
                    });
                    // MY GREYHOUND LOGIN
                    $(document).on('submit', '#login-form', function () {
                        $('#loginbutton').html("<div class='btn-content-centered'><i class='fa fa-refresh fa-spin'></i><span class='span-margin'>Logging in...</span></div>");
                        $('#loginbutton').attr('disabled', true);
                        $(this).closest('form').trigger('submit');
                    });
                    // SUBSCRIBE
                    $("#subscribeButton").on('click', function () {
                        $(this).html("<div class='btn-content-centered'><i class='fa fa-refresh fa-spin'></i><span class='span-margin'>Subscribing in...</span></div>");
                        $(this).attr('disabled', true);
                        $(this).closest('form').trigger('submit')
                    });
                }
            }
        });

})(window.ghd);
