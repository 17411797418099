(function (ghd) {

    ghd.plugins.register('categoryListing',
        function () {

            return {
                init: function () {
                    var $ = window.$;
                    function categoryListingFunc() {
                        // phone
                        if (Modernizr.mq('(max-width: 767px)')) {
                            $('.subcategory-small').css('height', '');
                        } else { // tablet or desktop
                            var maxHeight = 0;

                            $('.subcategory-small').each(function (idx, el) {
                                maxHeight = Math.max(maxHeight, $(el).outerHeight());
                            });
                            $('.subcategory-small').css('height', maxHeight);
                            var topOffsetToMaxHeightMap = new Map();
                            $('.subcategory-small').each(function (idx, el) {
                                var height = 0;
                                $(el).children().each(function (idx, child) {
                                    if (!$(child).hasClass('subcategory-body')) {
                                        height += $(child).outerHeight();
                                    }
                                });

                                var topOffset = $(el).offset().top;
                                if (topOffsetToMaxHeightMap.has(topOffset)) {
                                    var h = topOffsetToMaxHeightMap.get(topOffset);
                                    if (height > h) {
                                        topOffsetToMaxHeightMap.set(topOffset, height);
                                    }
                                } else {
                                    topOffsetToMaxHeightMap.set(topOffset, height);
                                }
                            });
                            $($('.subcategory-small').get().reverse()).each(function (idx, el) {
                                var img = $(el).find('img');

                                var titleHeight = $(el).find('.subcategory-title-wrapper').parent().outerHeight();
                                var imgHeight = img.outerHeight();

                                var totalHeight = titleHeight + imgHeight;

                                var topOffset = $(el).offset().top;
                                if (topOffsetToMaxHeightMap.get(topOffset) > totalHeight) {
                                    img.css('height', topOffsetToMaxHeightMap.get(topOffset) - titleHeight);
                                }
                            });
                            var topOffsetToMinHeightMap = new Map();
                            $('.subcategory-small').each(function (idx, el) {
                                var previousHeight = $(el).height();
                                $(el).css('height', '');
                                var height = $(el).outerHeight();
                                $(el).css('height', previousHeight);
                                var topOffset = $(el).offset().top;
                                if (topOffsetToMinHeightMap.has(topOffset)) {
                                    var h = topOffsetToMinHeightMap.get(topOffset);
                                    if (height < h) {
                                        topOffsetToMinHeightMap.set(topOffset, height);
                                    }
                                } else {
                                    topOffsetToMinHeightMap.set(topOffset, height);
                                }
                            });
                            $($('.subcategory-small').get().reverse()).each(function (idx, el) {
                                var topOffset = $(el).offset().top;
                                var h = topOffsetToMinHeightMap.get(topOffset);
                                $(el).height(h);
                            });
                            var topOffsetToMinHeightDescriptionMap = new Map();
                            $($('.subcategory-small').get().reverse()).each(function (idx, el) {
                                var topOffset = $(el).offset().top;
                                var heightDescription = $(el).find('.subcategory-description').height();
                                if (topOffsetToMinHeightDescriptionMap.has(topOffset)) {
                                    var h = topOffsetToMinHeightDescriptionMap.get(topOffset);
                                    if (heightDescription < h) {
                                        topOffsetToMinHeightDescriptionMap.set(topOffset, heightDescription);
                                    }
                                } else {
                                    topOffsetToMinHeightDescriptionMap.set(topOffset, heightDescription);
                                }
                            });
                            $($('.subcategory-small').get().reverse()).each(function (idx, el) {
                                var topOffset = $(el).offset().top;
                                var h = topOffsetToMinHeightDescriptionMap.get(topOffset);
                                var subcategoryDescription = $(el).find('.subcategory-description');
                                $clamp(subcategoryDescription[0], { clamp: h + 'px' }); // ellipsis
                            });


                        }
                    }

                    categoryListingFunc();
                    window.addEventListener("resize", categoryListingFunc);
                }
            }
        });

})(window.ghd);
