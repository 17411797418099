(function (ghd) {
    ghd.plugins.register('collapsing-list',
        function () {

            return {
                init: function () {
                    function toggleVisibilityPasses($li) {

                        var $liChildren = $li.children('.inner-list');
//                        var $innerList = $li.siblings('.inner-list');
                        $liChildren.toggleClass('hide');
//                        $innerList.toggleClass('hide');

                        var shouldShow = $liChildren.hasClass('hide');
                        var $chevron = $li.children('.list-header').children('.c-header__chevron');
                        $chevron.toggleClass('fa-chevron-down', !shouldShow);
                        $chevron.toggleClass('fa-chevron-right', shouldShow);
                    }

                    var $ = window.$;
                    var list = $(".collapsing-list");
                    list.on("click",'li', function () {
                        var $li = $(this);
                        toggleVisibilityPasses($li);
                        return false;
                    });

                    var links = $(".inner-list a");
                    links.on("click",  function () {
                        var $a = $(this);
                        window.location = $a.attr('href');
                    });
                }
            }
        });


})(window.ghd);
