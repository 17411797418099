(function (ghd) {

  ghd.plugins.register('sideMenu',
    function () {
  
  
      return {
        init: function () {

          var $ = window.$;

        var $body = $("body");
        var $menu = $("#c-menu--slide-left");
        var $mask = $("#c-mask");

          $body
            .on('click',
              '#menu-btn',
                function () {
                    if ($body.hasClass('has-active-menu')) {
                        console.log('closing');
                        $('#menu-btn').html('<i class="ga ga-menu"></i>');
                        $body.removeClass('has-active-menu');
                    } else {
                        console.log('opening');
                        $('#menu-btn').html('<i class="ga ga-menuclose"></i>');
                        $body.addClass('has-active-menu');
                        $('#menu-btn i').attr("id","menu-close");
                    }

                    if ($menu.hasClass('is-active')) {
                        $menu.removeClass('is-active');
                    } else {
                        $menu.addClass('is-active');
                    }

                    if ($mask.hasClass('is-active')) {
                        $mask.removeClass('is-active');
                    } else {
                        $mask.addClass('is-active');
                    }
              })
            .on('click touchstart',
              '#c-mask, .c-menu__close',
              function () {
                $body.removeClass('has-active-menu');
                $menu.removeClass('is-active');
                $mask.removeClass('is-active');
                $('#menu-btn').html( '<i class="ga ga-menu"></i>');
              });


          //fixing click on desktop menu.
          $('.desktop-navbar button.dropdown-toggle').click(function (event) {
            event.stopPropagation();
            $('ul.dropdown-menu:visible').toggle();
            var dropdown = $(this).parent().find('ul.dropdown-menu');
            dropdown.toggle();
          });

          // $('body').on('click', function (e) {
          //   $('ul.dropdown-menu:visible').toggle();
          // });shortMenuToggle
          $('#shortMenuToggle').click(function (event) {  // $('.dropdown.dropdown-accordion.visible-sm.visible-md').click(function (event) {
                 event.stopPropagation();
                $(this).parent().toggleClass('open');
            });
        }
      }
    });

})(window.ghd);


(function () {
  //OVERRIDE CONSOLE.LOG() TO LOG ON THE DOM SO WE CAN SEE ON MOBILE
  //var old = console.log;
  //console.log = function (message) {
  //    var $logger = $("#window-logger");
  //    if (typeof message == 'object') {
  //        $logger.prepend("<p>" + new Date().getTime() + ": " + (JSON && JSON.stringify ? JSON.stringify(message) : message) + "</p>");
  //    } else {
  //        $logger.prepend("<p>" + new Date().getTime() + ": " + message + "</p>");
  //    }
  //    //old(message);
  //}
})();
