(function (ghd) {

    ghd.plugins.register('igThumbMgr',
        function () {
            return {
                init: function () {
                    function updateInstagram() {
                        $('.ig-thumb-container').each(function(index) {
                            var accessToken = $(this).attr('at');
                            var maxThumbs = $(this).attr('maxThumbs') ? $(this).attr('maxThumbs') : 5;
                            var container = $(this);
                            $.getJSON('https://api.instagram.com/v1/users/self/media/recent/?access_token=' + accessToken + '&callback=?', function (instagram) {
                                $.each(instagram.data, function (photos, src) {
                                    if (photos === 0) {
                                        $(container).html('');
                                    }
                                    if (photos == maxThumbs) { return false; }
                                    $(container).append('<a href="' + src.link + '" class="post">' +
                                        '<div class="description">' + (src.caption ? src.caption.text : '') + '</div>' +
                                        '<div class="image" style="background-image:url(' + src.images.standard_resolution.url + ');"></div>' +
                                        '<ul>' +
                                        '<li><i class="fa fa-heart"></i> ' + src.likes.count + '</li>' +
                                        '<li><i class="fa fa-comment"></i> ' + src.comments.count + '</li>' +
                                        '</ul></a>');
                                });
                            });
                        });
                    }
                    $(window).on('resize',
                        function () {
                            updateInstagram();
                        });
                    updateInstagram();
                }
            };
        });

})(window.ghd);
