(function (ghd) {

    ghd.plugins.register('stickyHeader',
        function () {

            var $ = window.$;
            return {
                init: function () {
                    var that = this;

                    that._$win = $(window);
                    that._winT = that._$win.scrollTop();
                    that._oldPos = 0;
                    that._header = $("header").first();
                    that._pageWrapper = $("#page-wrapper").first();
                    that._headerH = that._header.outerHeight();
                    that._isDesktop = $(window).width() >= 1200;

                },
                onWindowResize: function (isDesktop) {
                    this._isDesktop = isDesktop;
                    this._handleOnWindowChange();
                },
                onWindowScroll: function () {
                    this._handleOnWindowChange();
                },

                _handleOnWindowChange: function () {
                    //TODO Run run all those who hold mortal souls below is horrible
                    var that = this,
                        bookingWidget = $(".booking-wgt").first(),
                        isExpanded = bookingWidget.hasClass("expanded"),
                        isAngularJsTopLevelComponent = function(){
                             var pathsThatUseAngularHeaderAndFooter = ['/',
                                        // '/home',
                                        // '/my-greyhound/profile',
                                        // '/my-greyhound/profile/',
                                        // '/my-greyhound/profile/edit',
                                        // '/my-greyhound/login',
                                        // '/my-greyhound/login/',
                                        // '/my-greyhound/create-account',
                                        // '/my-greyhound/create-account/',
                                        // '/my-greyhound/resetpassword',
                                        // '/my-greyhound/resetpassword/',
                                        // '/my-greyhound/change-password',
                                        // '/my-greyhound/change-password/',
                                        // '/my-greyhound/newsletter',
                                        // '/my-greyhound/newsletter/',
                                        // '/my-greyhound/newsletter-confirmation',
                                        // '/my-greyhound/newsletter-confirmation/',
                                        // '/privacy-policy',
                                        // '/privacy-policy/',
                                        // '/terms-and-conditions',
                                        // '/terms-and-conditions/',
                                        '/my-greyhound/forgot-password',
                                    ];
                            var path = window.location.pathname;
                            var isAnMcvView = pathsThatUseAngularHeaderAndFooter.indexOf(path) > -1
                            return  isAnMcvView;
                        };
                    //offsetTopMenu = that._isDesktop ? 84 + 1 : 67;
                    if ($(window).width() >= 1200) {
                        offsetTopMenu = 84 + 1;
                    } else if (bookingWidget.length > 0) {
                        offsetTopMenu = 67;
                    }
                    // else if (isAngularJsTopLevelComponent()) {
                    //     offsetTopMenu = 0;
                    // }
                    else { 
                        offsetTopMenu = 67;
                    }
                    outerHeight = that._header.outerHeight() + offsetTopMenu; //YES, get height everytime. don't cache it!

                    console.log('outerheight', outerHeight);
                    console.log('width', $(window).width() <= 1200);
                    console.log('width 2,', $(window).width());
                    //booking widget vertical position
                    bookingWidget.css({ top: outerHeight });
                    if (isExpanded) {
                        bookingWidget.css({ height: "calc(100% - " + outerHeight + "px)" });
                    } else {
                        bookingWidget.css({ height: "auto" });
                    }

                    if ($(window).width() >= 1200) {
                        that._header.removeClass("floated");
                        that._pageWrapper.css({ 'margin-top': 0 });
                        return;
                    } else {
                        if( $(window).width() <= 768) {
                         that._pageWrapper.css({ 'margin-top': outerHeight - 7 }); // This causes a block white block in IE. disabling for now.
                        }
                    }

                    //else if mobile
                    that._winT = that._$win.scrollTop();

                    if (that._oldPos !== that._winT) {
                        if ((that._winT > that._headerH || isExpanded) && that._oldPos < that._winT) {
                            that._header.addClass("floated");
                        } else {
                            that._header.removeClass("floated");
                        }
                    }

                    that._oldPos = that._winT;
                }
            }
        });

})(window.ghd);