(function (ghd) {

    ghd.plugins.register('travelPasses',
        function () {

            return {
                init: function () {

                    var $ = window.$;

                    var passes = $('.c-header', '.pass-list');
                    if (passes.length === 1) {
                        passes.click();
                    }

                    var $children = $('.pass-element', '.pass-detail')

                    $children.each(function () {
                        $(this).toggleClass('center-button', $(this).length === 1);
                    });

                    $(".pass-detail").on('click', '.to-passenger-details', function () {
                        var selectedValue = $(this).closest('.pass-detail').find('select.form-control').val()
                        if (selectedValue === 'default') {
                            $('.error-txt-msg').text('Please choose a pass option');
                            $('.error-txt-msg').parents('.error-container').removeClass('hide');
                            event.preventDefault();
                        } else {
                            event.preventDefault();
                            $('.error-txt-msg').text('');
                            $('.error-txt-msg').parents('.error-container').addClass('hide');
                            var split = selectedValue.split('#');
                            var passRef = split[0];
                            var category = split[1];
                            var href = $(this).attr('href');
                            location.href = href + 'passreference=' + passRef + '&categoryreference=' + category;
                        }
                    });

                    var previousTravelPass = localStorage.getItem('previous_travel_pass');
                    if (previousTravelPass) {
                        $('.pass-detail .pass-element a').each(function (i, el) {
                            // search for the pass reference previously saved
                            if ($(this).attr('href').indexOf(previousTravelPass) !== -1) {
                                // if we found it, we force scroll and expand the element
                                var parentTravelPass = $(this).parents('section.pass-detail');
                                parentTravelPass.find('.c-header').click();
                                $('html, body').animate({
                                    scrollTop: parentTravelPass.offset().top
                                }, 2000);
                            }
                        });
                        localStorage.removeItem('previous_travel_pass');
                    }
                }
            }
        });

})(window.ghd);
